<template>
  <div id="app">
    <router-view :key="refreshKey" />
    <van-popup
      :close-on-click-overlay="false"
      class="van-popup--bottom"
      v-model="$store.state.isShow"
      position="bottom"
    >
      <van-picker
        :confirm-button-text="$t('language.Confirm')"
        :cancel-button-text="$t('language.cancel')"
        @confirm="onConfirm"
        @cancel="onCancel"
        show-toolbar
        value-key="name"
        :columns="columns"
        :default-index="$store.state.defaultIndex"
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      refreshKey: true,
      defaultIndex: 0,
      columns: [
        {
          name: "中文",
          value: "zh-cn",
        },
        {
          name: "English",
          value: "en-us",
        },
        {
          name: "Español",
          value: "es-es",
        },
        {
          name: "Polski",
          value: "pl-pl",
        },
        {
          name: "čeština",
          value: "cs-cz",
        },
        {
          name: "dansk",
          value: "da-dk",
        },
        {
          name: "Ελληνικά",
          value: "el-gr",
        },
        {
          name: "Limba Română",
          value: "ro-ro",
        },
        {
          name: "Slovenský",
          value: "sk-sk",
        },
        {
          name: "Deutsch",
          value: "de-de",
        },
        {
          name: "Italiano",
          value: "it-it",
        },
        {
          name: "日本語",
          value: "ja-jp",
        },
        {
          name: "Türkçe",
          value: "tr-tr",
        },
        {
          name: "عربي",
          value: "ar-ae",
        },
        {
          name: "Nederlands",
          value: "nl-nl",
        },
        {
          name: "বাংলা",
          value: "bn-bn",
        },
        {
          name: "한국인",
          value: "ko-kr",
        },
        {
          name: "Русский",
          value: "ru-ru",
        },
      ],
    };
  },
  methods: {
    onConfirm(value, index) {
      // console.log(value, index);
      this.$i18n.locale = value.value;
      this.$store.commit("changeLanguage", value.value);
      this.$store.commit("changeDefaultIndex", index);
      this.$store.commit("changeIsShow", false);
      // this.$router.go(0)
      this.refreshKey = !this.refreshKey;
    },
    onCancel() {
      this.$store.commit("changeIsShow", false);
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
#app {
  max-width: 500px;
  min-height: 100vh;
  margin: 0 auto;
  font-size: 16px;
  font-family: Inter;

  .van-popup--bottom {
    max-width: 500px;
    margin: 0 auto;
    right: 0;
  }
}
:root {
  --BoxColor: #3F52E3;
  --BtnColor: #3F52E3;
}
</style>
