export default {
  "logo": { "name": "Task Platform", "msg": "Task Platform", "wz": "URL：" },
  "head": { "Language": "日本語" },
  "menu": {
    "home": "表紙",
    "order": "注文",
    "trading": "貿易",
    "earnings": "チーム",
    "mine": "ユーザー"
  },
  "back": {
    "appDownload": "アプリのダウンロード",
    "help": "ヘルプ",
    "order": "注文",
    "trading": "貿易",
    "income": "チーム",
    "revenueDetails": "詳細",
    "Withdrawal": "撤回する",
    "Recharge": "補充します",
    "Invitefriends": "友達を招待",
    "personalinformation": "個人情報",
    "gywm": "私たちについて",
    "kf": "顧客サービス"
  },
  "loading": {
    "loadingText": "読み込み...",
    "finishedText": "もうない",
    "loadingText2": "マッチング...",
    "loadingLogin": "ログイン...",
    "loadingSign": "登録する ...",
    "confirmOrder": "トランザクションで...",
    "ldg1": "撤退中..."
  },
  "Toast": { "copy": "複製", "ts": "言語を選択してください" },
  "login": {
    "title": "こんにちは",
    "msg": "あなたのアカウントにログイン",
    "phone": "アカウント",
    "phoneCode": "地域コード",
    "password": "パスワード",
    "remember": "私を覚えてますか",
    "forget": "パスワードを忘れますか？",
    "loginBtn": "ログイン",
    "noAccount": "アカウントはありませんか？",
    "goRegister": "登録する",
    "lg1": "その他のアカウントログイン"
  },
  "sign": {
    "phone": "電話番号",
    "phoneCode": "地域コード",
    "Nickname": "ニックネーム",
    "code": "検証コード",
    "sendCode": "確認コードを送信します",
    "password": "パスワード",
    "confirmPassword": "パスワードを認証する",
    "recommendationCode": "推奨コード",
    "protocol": "登録とは、契約条件に同意することを意味します",
    "registerBtn": "登録する",
    "Existing": "既存のアカウント？",
    "goLogin": "ログイン",
    "rule": {
      "phone": "アカウントを空にすることはできません、アカウント形式は正しくありません",
      "nickname": "ニックネームは記入する必要があります",
      "password": "パスワードは空にすることはできません。パスワードは最小限の6桁で、パスワードは矛盾しています",
      "code": "検証コードを入力する必要があります",
      "isAgree": "最初に同意を確認してください"
    }
  },
  "home": {
    "cumulativeIncome": "累積収入",
    "notification": "通知します",
    "moduleTitle1": "私のサービス",
    "moduleTitle2": "相棒",
    "title": "通知します"
  },
  "download": {
    //"Task Platform": "ティクトクストア",
    "AndroidDownload": "Androidダウンロード",
    "iOSDownload": "Appleダウンロード"
  },
  "help": {
    "bz2": "一般的な問題",
    "bz3": "取引ルール",
    "bz4": "お金を引き出す方法",
    "bz5": "充電方法",
    "bz6": "1.どのようなイストクショップ？",
    "bz7": "Task Platformは、世界中のTask Platformオンラインストアの商人を支援するマーケティングプロモーション会社であり、より多くの注文販売を獲得し、Task Platformオンラインストアからの視聴データを増やしています。 私たちは、Task Platformと商人と消費者の間に3つのパーティー利益マーケティングモデルを確立することに取り組んでいます。 最新のP2Pブロックチェーンテクノロジーを組み合わせて、USDT（TRC20、ERC20）を介して消費者と商人を迅速に接続します。 登録ユーザーは注文の手数料を取得できますが、商人はストアの販売データを増やすことができます。 インターネットブロックチェーンモデルの最新の利益モデルです！",
    "bz8": "2. OnTask Platformの作業方法は？",
    "bz9": "Task Platformの毎日のフィードバックを通じて、Task Platformの販売で改善する必要がある製品は、注文を自動的に生成するために注文を簡単にクリックする必要があります。 ユーザーは、ブロックチェーンUSDTを介して注文金額を支払い、毎日のコミッションを取得します。",
    "bz10": "3. 2つの取引で同じ通貨の価格に違いがあるのはなぜですか？",
    "bz11": "価格の違いは、通貨移転の速度、ネットワーク条件、通貨アクセス制限、異なる地域の人々、さらには取引所が提供する取引タイプと取引など、通貨の自由を助長しないすべての要因によって引き起こされます。 したがって、同じ通貨が異なるトランザクションに価格の違いを引き起こす可能性があります。",
    "bz12": "4.投資利益？",
    "bz13": "価格が上昇するほど、利益が高くなります。 同時に、Task Platformは大規模な委員会の命令をランダムに割り当てます。",
    "bz14": "毎日、システムはユーザーに60の商品注文を自動的に生成して配布します。 大規模な委員会の命令はランダムに配布されます。",
    "bz15": "USDTは60注文後に撤回できます。 （60の注文が完了していない場合、システムは1日の余剰注文額まで自動的に停止します）",
    "bz16": "各アカウントは、USDTアカウントにのみバインドできます",
    "bz17": "毎日の注文配信を完了した後、撤退を開始した後、24時間以内にアカウントを取得できます。",
    "bz18": "リチャージの前に、引き出しアドレス（Support TRC-20）USDTアドレスをクリックして、リチャージに使用するブロックチェーン（TRC-20）を選択できます。",
  "bz19": "相互充電や銀行の転送などの禁止された行動があり、関連するアカウント間の交換の転送が悪意を持って取得されます。"

  },
  "order": {
    "incomplete": "元に戻す",
    "completed": "完了しました",
    "orderNumber": "注文番号",
    "transactionTime": "トランザクション時間",
    "price": "額",
    "expectedReturns": "期待されるリターン",
    "submit": "注文を送信"
  },
  "trading": {
    "accountBalance": "勘定残高",
    "introduce": "トランザクションの紹介",
    "freeze": "完成した番号",
    "walletBalance": "合計タスク",
    "startMatching": "一致を開始します",
    "yield": "今日の利点",
    "orderNumber": "注文番号",
    "transactionTime": "トランザクション時間",
    "price": "額",
    "expectedReturns": "期待されるリターン",
    "startTrading": "取引を開始します",
    "cancel": "キャンセル",
    "ts": "バランスが不十分で、まだ貧しい",
    "msg": "Task Platformは、Task Platformからのフィードバックを毎日使用して、Task Platformの販売を改善する必要がある製品を表示します。 ユーザーが注文のみをクリックする必要がある場合、システムは注文署名を自動的に生成します。 ユーザーはUSDTブロックチェーンを介して注文金額を支払い、毎回委員会の0.6％以上を獲得でき、システムは大規模な報酬注文をランダムに配布します。",
    "ts2": "ヒント",
    "ts3": "残高不足",
    "ts4": "リチャージに移動します",
    "ts5": "キャンセル"
  },
  "income": {
    "totalRevenue": "バランス",
    "residualIncome": "手数料",
    "claim": "受け取る",
    "moduleTitle": "私のチーム",
    "headcount": "総人",
    "totalRecharge": "総充電",
    "grade": "ユーザー",
    "numberOfPeople": "貢献（数量）",
    "income": "州",
    "viewDetails": "詳細を見る",
    "task_num": "元に戻す",
    "task_num2": "完了しました"
  },
  "revenueDetails": {
    "totalRevenue": "総収入",
    "headcount": "総人",
    "totalRecharge": "総充電",
    "userLevel1": "ユーザーレベル1",
    "userLevel2": "ユーザーレベル2",
    "userLevel3": "ユーザーレベル3",
    "Nickname": "ニックネーム",
    "phone": "電話番号",
    "Recharge": "補充します",
    "income": "所得",
    "date": "登録時間"
  },
  "user": {
    "Myaccount": "私のアカウント",
    "Withdrawal": "撤回する",
    "Recharge": "補充します",
    "InviteFriend": "友達を招待してください",
    "InviteFriendTxt": "友人が獲得したすべての利益について、あなたは対応する比率委員会を取得します",
    "InviteFriendBtn": "今すぐ招待してください",
    "MyInformation": "個人情報",
    "FundsDetails": "資本の詳細",
    "RechargeRecord": "リチャージレコード",
    "WithdrawalRecord": "引き出し記録",
    "Language": "言語",
    "Logout": "サインアウト"
  },
  "Withdrawal": {
    "address": "引き出し住所",
    "placeholder1": "引き出し住所",
    "availableBalance": "利用可能残高",
    "placeholder2": "引き出し額",
    "actualBalance": "実は",
    "placeholder3": "実は",
    "password": "パスワード",
    "placeholder4": "6-16ビットパスワードを入力してください",
    "submit": "提出する",
    "amount": "利用可能な量よりも大きくはありません",
    "w1": "手数料",
    "ts1": "引き出しアドレスを取得してください",
    "ts2": "引き出し額を入力してください"
  },
  "Recharge": {
    "qrcode": "スキャンコードリチャージ",
    "copy": "クリックしてコピーします",
    "cotakecarepy": "注意してください、関連する考慮事項は、画面またはページの不動産を使用することです",
    "ts1": "充電額",
    "ts2": "その他の量",
    "ts3": "もちろん",
    "ts4": "最初にウォレットを接続してください",
    "ts5": "リチャージ額を選択してください",
    "ts6": "リチャージで..."
  },
  "Invitefriends": {
    "inviteCode": "招待コード",
    "inviteTxt": "友達を招待し、大量の収入を楽しんでください、招待してください",
    "copy": "クリックしてコピーします",
    "Rebaterules": "リベート",
    "RebaterulesMsg": "次の条件を満たす必要があります",
    "scale": "委員会の規則は次のとおりです",
    "Userlevel": "ユーザーレベル",
    "Rules": "ルール",
    "Commissionratio": "手数料率",
    "ps": "委員会はUSDTで解決され、いつでもあなたの財布に撤回することができます"
  },
  "personalinformation": {
    "Nickname": "ニックネーム",
    "phone": "アカウント",
    "Loginpassword": "ログインパスワード",
    "address": "引き出し住所",
    "modify": "改訂",
    "Confirm": "もちろん"
  },
  "fundDetails": {
    "amount": "額",
    "revenue": "所得",
    "time": "時間",
    "withdrawal": "撤回する",
    "recharge": "補充します",
    "type": {
    "1": "補充します",
    "2": "撤回する",
    "3": "委員会のコレクション",
    "4": "所得",
    "5": "撤回する",
    "6": "保留中",
    "7": "完了しました",
    "8": "拒否された"
  }
  },
  "language": {
    "placeholder": "国際的な市外局番を入力してください",
    "Confirm": "もちろん",
    "cancel": "キャンセル"
  },
  "request": {
    "loadingText": "読み込み...",
    "Serverexception": "サーバーの異常"
  },
  "newLang": { "a1": "バランスは貧弱です", "a2": "現在のタスク番号","a3": "最初に拘束力のある離脱アドレスに移動してください" ,"a4": "残高不足", "a5": "充電するかどうか"},
  "newLang2": {
    "a1": "バインディングアドレスについては、カスタマーサービスに連絡して変更してください",
    "a2": "充電する必要があります",
    "a3": "パスワードを変更するには、カスタマーサービスに連絡してください"
  }
}
