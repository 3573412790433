import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang'
import './style/reset.css'
import {initRootFontSize} from './uits/initRootFontSize.js'
import 'amfe-flexible'
import 'view-design/dist/styles/iview.css';
import 'vant/lib/index.css';
import './uits/iview.js'
import './uits/vant.js'

initRootFontSize()
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
