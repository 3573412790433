export default {
  "logo": { "name": "Task Platform", "msg": "Task Platform", "wz": "URL:" },
  "head": { "Language": "Deutsch" },
  "menu": {
    "home": "Titelseite",
    "order": "Befehl",
    "trading": "Handel",
    "earnings": "Team",
    "mine": "Benutzer"
  },
  "back": {
    "appDownload": "App -Download",
    "help": "helfen",
    "order": "Befehl",
    "trading": "Handel",
    "income": "Team",
    "revenueDetails": "Detail",
    "Withdrawal": "zurückziehen",
    "Recharge": "nachfüllen",
    "Invitefriends": "Freunde einladen",
    "personalinformation": "Persönliche Angaben",
    "gywm": "über uns",
    "kf": "Kundendienst"
  },
  "loading": {
    "loadingText": "Wird geladen...",
    "finishedText": "Nicht mehr",
    "loadingText2": "Matching ...",
    "loadingLogin": "Einloggen...",
    "loadingSign": "Registrieren ...",
    "confirmOrder": "in der Transaktion ...",
    "ldg1": "Im Rückzug ..."
  },
  "Toast": { "copy": "Reproduzieren", "ts": "Bitte wählen Sie Sprache" },
  "login": {
    "title": "Hallo",
    "msg": "Ins Konto einloggen",
    "phone": "Konto",
    "phoneCode": "Postleitzahl",
    "password": "Passwort",
    "remember": "erinnere dich an mich",
    "forget": "das Passwort vergessen?",
    "loginBtn": "Anmeldung",
    "noAccount": "Kein Account?",
    "goRegister": "Registrieren",
    "lg1": "Andere Kontoanmeldung"
  },
  "sign": {
    "phone": "Telefonnummer",
    "phoneCode": "Postleitzahl",
    "Nickname": "Spitzname",
    "code": "Bestätigungscode",
    "sendCode": "Senden Sie den Verifizierungscode",
    "password": "Passwort",
    "confirmPassword": "Bestätige das Passwort",
    "recommendationCode": "Empfehlungscode",
    "protocol": "Register bedeutet, dass Sie unseren Allgemeinen Geschäftsbedingungen zustimmen",
    "registerBtn": "registrieren",
    "Existing": "Bestehendes Konto?",
    "goLogin": "Anmeldung",
    "rule": {
      "phone": "Das Konto kann nicht leer sein, das Kontoformat ist falsch",
      "nickname": "Spitzname sollte gefüllt werden",
      "password": "Das Passwort kann nicht leer sein, das Passwort ist minimal 6 Ziffern und das Passwort ist inkonsistent.",
      "code": "Der Verifizierungscode muss ausgefüllt werden",
      "isAgree": "Bitte überprüfen Sie zuerst die Zustimmung"
    }
  },
  "home": {
    "cumulativeIncome": "Kumulatives Einkommen",
    "notification": "benachrichtigen",
    "moduleTitle1": "Mein Service",
    "moduleTitle2": "Partner",
    "title": "benachrichtigen"
  },
  "download": {
    //"Task Platform": "Task Platform Store",
    "AndroidDownload": "Android -Download",
    "iOSDownload": "Apple Download"
  },
  "help": {
    "bz2": "häufiges Problem",
    "bz3": "Handelsregeln",
    "bz4": "Wie man Geld abhebt",
    "bz5": "Wie man auflädt",
    "bz6": "1. Was istTask Platform?",
    "bz7": "Task Platform ist ein Marketing -Werbeunternehmen, das Task Platform Online -Shop -Händlern aus aller Welt hilft, mehr Bestellverkäufe zu erzielen und die Anzeigendaten aus Task Platform -Online -Stores zu erhöhen. Wir sind bestrebt, ein Drei -Party -Gewinnmarketingmodell zwischen Task Platform und Händlern und Verbrauchern aufzubauen. Kombinieren Sie die neueste P2P -Blockchain -Technologie und verbinden Verbraucher und Händler schnell über USDT (TRC20, ERC20). Registrierte Benutzer können die Kommission für Bestellungen erhalten, während Händler die Verkaufsdaten des Geschäfts erhöhen können. Es ist das neueste Gewinnmodell im Internet -Blockchain -Modell!",
    "bz8": "2. Wie arbeite man OnTask Platform?",
    "bz9": "Durch das tägliche Feedback von Task Platform müssen Produkte, die bei Task Platform -Verkauf verbessert werden müssen, verbessert werden. Benutzer zahlen den Bestellbetrag über den Blockchain -USDT und erhalten tägliche Provisionen.",
    "bz10": "3. Warum gibt es einen Unterschied im Preis der gleichen Währung in den beiden Transaktionen?",
    "bz11": "Preisunterschiede werden durch alle Faktoren verursacht, die der Währungsfreiheit nicht förderlich sind, einschließlich der Geschwindigkeit der Währungsübertragung, der Netzwerkbedingungen, der Beschränkungen des Währungszugriffs, der Personen in verschiedenen Regionen und sogar den vom Austausch bereitgestellten Transaktionen. Daher kann die gleiche Währung Preisunterschiede in verschiedenen Transaktionen verursachen.",
    "bz12": "4. Investitionsgewinn?",
    "bz13": "Je höher der Preis, den Sie erhalten, desto höher ist der Gewinn, den Sie erzielen. Gleichzeitig weist Task Platform zufällig große Kommissionsbestellungen zu.",
    "bz14": "Jeden Tag generiert das System automatisch 60 Warenbestellungen an den Benutzer. Große Kommissionsbestellungen werden zufällig verteilt.",
    "bz15": "USDT kann nach 60 Bestellungen zurückgezogen werden. (Wenn die 60 Bestellungen nicht abgeschlossen sind, stoppt das System automatisch, bis der Überschussauftragsbetrag des Tages beauftragt wird.)",
    "bz16": "Jedes Konto kann nur an ein USDT -Konto gebunden sein.",
    "bz17": "Nach Abschluss der täglichen Auftragszustellung können Sie sich normal zurückziehen.",
    "bz18": "Binden Sie vor dem Aufladen Ihre Abhebungsadresse (Support TRC-20) USDT-Adresse.",
  "bz19": "Es gibt verbotene Verhaltensweisen wie die gegenseitige Aufladung oder Bankübertragungen und die Umtauschübertragungen zwischen verwandten Konten."

  },
  "order": {
    "incomplete": "rückgängig gemacht",
    "completed": "vollendet",
    "orderNumber": "Bestellnummer",
    "transactionTime": "Transaktionsstunde",
    "price": "Menge",
    "expectedReturns": "Erwartete Rückkehr",
    "submit": "Bestellung abschicken"
  },
  "trading": {
    "accountBalance": "Kontostand",
    "introduce": "Transaktion Einführung",
    "freeze": "Abgeschlossene Nummer",
    "walletBalance": "Gesamtaufgabe",
    "startMatching": "Starten Sie passungen",
    "yield": "Die heutigen Vorteile",
    "orderNumber": "Bestellnummer",
    "transactionTime": "Transaktionsstunde",
    "price": "Menge",
    "expectedReturns": "Erwartete Rückkehr",
    "startTrading": "Mit dem handel beginnen",
    "cancel": "Stornieren",
    "ts": "Unzureichendes Gleichgewicht, immer noch arm",
    "msg": "Task Platform verwendet täglich Feedback von Task Platform, um Produkte anzuzeigen, die für den Verkauf von Task Platform verbessert werden müssen. Wenn der Benutzer nur auf die Bestellung klicken muss, generiert das System automatisch eine Bestellsignatur. Der Benutzer zahlt den Bestellbetrag über die USDT -Blockchain und kann jedes Mal mehr als 0,6%der Provision erhalten, und das System verteilt zufällig große Belohnungsaufträge.",
    "ts2": "Hinweis",
    "ts3": "Mangelhaftes Gleichgewicht",
    "ts4": "Gehen Sie zum Aufladen",
    "ts5": "Stornieren"
  },
  "income": {
    "totalRevenue": "Gleichgewicht",
    "residualIncome": "Kommission",
    "claim": "erhalten",
    "moduleTitle": "mein Team",
    "headcount": "Totale Menschen",
    "totalRecharge": "Gesamtaufladung",
    "grade": "Benutzer",
    "numberOfPeople": "Beitrag (Menge)",
    "income": "Zustand",
    "viewDetails": "Details anzeigen",
    "task_num": "rückgängig gemacht",
    "task_num2": "vollendet"
  },
  "revenueDetails": {
    "totalRevenue": "Gesamtumsatz",
    "headcount": "Totale Menschen",
    "totalRecharge": "Gesamtaufladung",
    "userLevel1": "Benutzerstufe 1",
    "userLevel2": "Benutzerstufe 2",
    "userLevel3": "Benutzerstufe 3",
    "Nickname": "Spitzname",
    "phone": "Telefonnummer",
    "Recharge": "nachfüllen",
    "income": "Einkommen",
    "date": "Registrierungszeit"
  },
  "user": {
    "Myaccount": "mein Konto",
    "Withdrawal": "zurückziehen",
    "Recharge": "nachfüllen",
    "InviteFriend": "Lade deine Freunde ein",
    "InviteFriendTxt": "Für jeden von Freunden erzielten Gewinn erhalten Sie die entsprechende Proportionsprovision",
    "InviteFriendBtn": "Jetzt einladern",
    "MyInformation": "Persönliche Angaben",
    "FundsDetails": "Kapitaldetails",
    "RechargeRecord": "Aufzeichnung aufladen",
    "WithdrawalRecord": "Auszahlungsaufzeichnung",
    "Language": "Sprache",
    "Logout": "Abmelden"
  },
  "Withdrawal": {
    "address": "Auszahlungsadresse",
    "placeholder1": "Auszahlungsadresse",
    "availableBalance": "Verfügbares Guthaben",
    "placeholder2": "Auszahlungsbetrag",
    "actualBalance": "Eigentlich",
    "placeholder3": "Eigentlich",
    "password": "Passwort",
    "placeholder4": "Bitte geben Sie das 6-16-Bit-Passwort ein",
    "submit": "einreichen",
    "amount": "Nicht größer als verfügbare Mengen",
    "w1": "Bearbeitungsgebühr",
    "ts1": "Bitte holen Sie sich die Abhebungsadresse",
    "ts2": "Bitte geben Sie den Auszahlungsbetrag ein"
  },
  "Recharge": {
    "qrcode": "Scan -Code -Aufladung",
    "copy": "Klicken Sie hier, um zu kopieren",
    "cotakecarepy": "Beachten Sie, dass eine verwandte Überlegung die Verwendung Ihres Bildschirms oder Ihrer Seitenimmobilie verwendet",
    "ts1": "Betrag aufladen",
    "ts2": "Andere Menge",
    "ts3": "Sicher",
    "ts4": "Bitte verbinden Sie zuerst die Brieftasche",
    "ts5": "Bitte wählen Sie den Aufladungsbetrag aus",
    "ts6": "In Aufladung ..."
  },
  "Invitefriends": {
    "inviteCode": "Einladungscode",
    "inviteTxt": "Laden Sie Freunde ein, genießen Sie ein großes Einkommen, bitte laden Sie ein",
    "copy": "Klicken Sie hier, um zu kopieren",
    "Rebaterules": "Abzug",
    "RebaterulesMsg": "Die folgenden Bedingungen müssen erfüllt sein",
    "scale": "Regeln für die Kommissionsquote sind wie folgt",
    "Userlevel": "Benutzerlevel",
    "Rules": "Regel",
    "Commissionratio": "Provisionssatz",
    "ps": "Die Kommission wird mit USDT beigelegt, was jederzeit in Ihre Brieftasche zurückgezogen werden kann"
  },
  "personalinformation": {
    "Nickname": "Spitzname",
    "phone": "Konto",
    "Loginpassword": "Passwort",
    "address": "Auszahlungsadresse",
    "modify": "Überarbeiten",
    "Confirm": "Sicher"
  },
  "fundDetails": {
    "amount": "Menge",
    "revenue": "Einkommen",
    "time": "Zeit",
    "withdrawal": "zurückziehen",
    "recharge": "nachfüllen",
    "type": {
    "1": "nachfüllen",
    "2": "zurückziehen",
    "3": "Sammlung von Provision",
    "4": "Einkommen",
    "5": "Zurückziehen",
    "6": "Ausstehend",
    "7": "vollendet",
    "8": "abgelehnt"
  }
  },
  "language": {
    "placeholder": "Bitte geben Sie den internationalen Vorwahlkodex ein",
    "Confirm": "Sicher",
    "cancel": "Stornieren"
  },
  "request": {
    "loadingText": "Wird geladen...",
    "Serverexception": "Serveranomalien"
  },
  "newLang": {
    "a1": "Das Gleichgewicht ist schlecht",
    "a2": "Aktuelle Aufgabenummer",
    "a3": "Bitte gehen Sie zuerst zur Bindungsabhebungsadresse",
    "a4": "Mangelhaftes Gleichgewicht", 
    "a5": "Ob aufgeladen werden soll"
  },
  "newLang2": {
    "a1": "Für die Bindungsadresse wenden Sie sich bitte an den Kundendienst, um zu ändern",
    "a2": "Müssen aufladen",
    "a3": "Bitte wenden Sie sich an den Kundendienst, um das Passwort zu ändern"
  }
}
