export default {
    logo:{
        name:'Task Platform',
        msg:'Task Platform订单中心',
        wz:' 网址: '
    },
    head: {
        Language: "中文",
    },
    menu:{
        home: '首页',
        order:'订单',
        trading:'交易',
        earnings:'团队',
        mine:'用户',
    },
    back:{
        appDownload:'APP下载',
        help:'帮助',
        order:'订单',
        trading:'交易',
        income:'团队',
        revenueDetails:'收益明细',
        Withdrawal:'提现',
        Recharge:'充值',
        Invitefriends:'邀请好友',
        personalinformation:'个人信息',
        gywm:'关于我们',
        kf:'客服',
    },
    loading:{
        loadingText:'加载中...',
        finishedText:"没有更多了",
        loadingText2:'匹配中...',
        loadingLogin:'登录中...',
        loadingSign:'注册中...',
        confirmOrder:'交易中...'
    },
    Toast:{
        copy:'复制成功',
        ts:'请选择语言'
    },
    login:{
        title:'你好',
        msg:'登录到您的帐户',
        phone:'电话',
        phoneCode:'区号',
        password:'密码',
        remember:'记住我',
        forget:'忘记密码?',
        loginBtn:'登录',
        noAccount:'没有账号？',
        goRegister:'去注册'
    },
    sign:{
        phone:'手机号',
        phoneCode:'区号',
        Nickname:'昵称',
        code:'验证码',
        sendCode:'发送验证码',
        password:'密码',
        confirmPassword:'确认密码',
        recommendationCode:'推荐码',
        protocol:'注册即表示您同意我们的条款和条件',
        registerBtn:'注册',
        Existing:'已有账户？',
        goLogin:'去登录',
        rule:{
            phone:['手机号不能为空','手机号格式不正确'],
            nickname:['昵称不能为空'],
            password:['密码不能为空','密码最小6位数','密码不一致','确认密码不能为空'],
            code:['验证码不能为空'],
            isAgree:['请先勾选同意']
        }
    },
    home:{
        cumulativeIncome:'累计收益',
        notification:'通知',
        moduleTitle1:'我的服务',
        moduleTitle2:'合作伙伴'
    },
    // download:{
    //     Task Platform:'Task Platform商店',
    //     AndroidDownload:'安卓下载',
    //     iOSDownload:'苹果下载',
    // },
    help:{
        'bz2': '常见问题',
        'bz3': '交易规则',
        'bz4': '如何提款',
        'bz5': '如何充值',
        'bz6': '1.Task Platform 是什么？',
        'bz7': 'Task Platform是一家营销推广公司，帮助世界各地的Task Platform网店商家获得更多的订单销售和增加Task Platform网店的浏览数据。我们致力于在Task Platform和商家以及消费者之间建立三方盈利的营销推广模式。结合最新的P2P区块链技术，通过USDT（TRC20，ERC20）快速连接消费者和商家。注册的用户可以获得订单的佣金，而商家则可以增加商店的销售数据。是互联网区块链模式中最新的盈利模式！',
        'bz8': '2.Task Platform 如何工作？',
        'bz9': '通过Task Platform的日常反馈显示，在Task Platform销售需要改进的产品，用户只需要轻松点击订单，系统自动生成订单订阅。用户通过区块链USDT支付订单金额，并获得每日佣金。',
        'bz10': '3.为什么两笔交易中同一货币的价格会有差异？',
        'bz11': '价格差异是由所有不利于货币自由流通的因素造成的，包括货币转移的速度、网络条件、货币准入限制、不同地区的人对货币的认可，甚至交易所提供的交易对类型、交易等。因此，同样的货币在不同的交易中可能会产生价格差异。',
        'bz12': '4.投资利润？',
        'bz13': '你得到的商品价格越高，你得到的订单利润就越高。同时，Task Platform随机分配大额佣金订单。',
        'bz14': '每天，系统自动生成并分发60个商品订单给用户，用户通过USDT完成每个订单的支付，获得0.6%的佣金。大额佣金订单是随机分配的。',
        'bz15': 'USDT在60个订单后可以提现。(如果60个订单没有完成，系统将自动停止到当天的剩余订单量)',
        'bz16': '每个账户只能绑定一个USDT账户，如果发生错误，请联系客服',
        'bz17': '完成每日订单交付后，可以正常提现，启动提现后24小时内到账，且无提现限制',
        'bz18': '充值前请绑定您的提现地址（支持TRC-20）USDT地址，您可以到APP或网页上点击充值，选择您使用的区块链（TRC-20）进行充值。',
    "bz19":"关联账户之间禁止存在互相充值或银行转账、交易所转账等行为，该行为涉嫌恶意抢单，发现该行为将对账号进行封禁处罚"

    },
    order:{
        incomplete:'未完成',
        completed:'已完成',
        orderNumber:'订单号',
        transactionTime:'交易时间',
        price:'金额',
        expectedReturns:'预期收益',
        // 提交
        submit:'提交订单'
    },
    trading:{
        accountBalance:'账户余额',
        introduce:'交易介绍',
        freeze:'已完成数',
        walletBalance:'总任务数',
        startMatching:'开始匹配',
        yield:'今日收益',
        orderNumber:'订单号',
        transactionTime:'交易时间',
        price:'金额',
        expectedReturns:'预期收益',
        startTrading:'开始交易',
        cancel:'取消',
        ts:'余额不足，还差',
        msg:'Task Platform 每天使用Task Platform的反馈来展示Task Platform销售的需要改进的产品。用户只需点击订单，系统就会自动生成订单签名。用户通过USDT区块链支付订单金额，每次可获得0.6%以上的佣金，系统随机分配大额奖励订单。'
    },
    income:{
        totalRevenue:'余额',
        residualIncome:'佣金',
        claim:'领取',
        moduleTitle:'我的团队',
        headcount:'总人数',
        totalRecharge:'总充值',
        grade:'用户',
        numberOfPeople:'贡献(数量)',
        income:'状态',
        viewDetails:'查看明细',
        task_num:'未完成',
        task_num2:'已完成',
    },
    revenueDetails:{
        totalRevenue:'总收益',
        headcount:'总人数',
        totalRecharge:'总充值',
        userLevel1:"用户级别1",
        userLevel2:"用户级别2",
        userLevel3:"用户级别3",
        Nickname:'昵称',
        phone:'手机号',
        Recharge:'充值',
        income:'收益',
        date:'注册时间'
    },
    user:{
        Myaccount:'我的账户',
        Withdrawal:'提现',
        Recharge:'充值',
        InviteFriend:'邀请你的好友',
        InviteFriendTxt:'好友注册后赚取的每笔利润，您都将获得相应比例的佣金',
        InviteFriendBtn:'立即邀请',
        MyInformation:'个人信息',
        FundsDetails:'资金明细',
        RechargeRecord:'充值记录',
        WithdrawalRecord:'提现记录',
        Language:'语言',
        Logout:'退出登录',

    },
    Withdrawal:{
        address:'提现地址',
        placeholder1:'提现地址',
        availableBalance:'可用余额',
        placeholder2:'提现金额',
        actualBalance:'实际到账',
        placeholder3:'实际到账',
        password:'密码',
        placeholder4:'请输入6-16位密码',
        submit:'提交',
        amount:'不可大于可用金额'
    },
    Recharge:{
        qrcode:'扫码充值',
        copy:'点击复制',
        cotakecarepy:'注意事项, 一个相关的考虑因素是使用您的屏幕或页面房地产',
    },
    Invitefriends:{
        inviteCode:'邀请码',
        inviteTxt:'邀请好友，享受大额收益 快去邀请吧',
        copy:'点击复制',
        Rebaterules:'返佣规则',
        RebaterulesMsg:'必须满足以下条件',
        scale:'佣金比例规则如下',
        Userlevel:' 用户等级 ',
        Rules:'规则',
        Commissionratio:'佣金比例',
        ps:'佣金以USDT结算，可随时提现到您的钱包'
    },
    personalinformation:{
        Nickname:'昵称',
        phone:'手机号',
        Loginpassword:'登录密码',
        address:'提现地址',
        modify:'修改',
        Confirm:'确定'
    },
    fundDetails:{
        amount:'金额',
        revenue:'收益',
        time:'时间',
        withdrawal:'提现',
        recharge:'充值',
        type: {
            1: '充值',
            2: '提现',
            3: '佣金领取',
            4: '收益',
            5: '提现驳回',
            6:"待处理",
            7:"已完成",
            8:"已拒绝"
        }
    },
    language:{
        placeholder:'请输入国际区号',
        Confirm:'确定',
        cancel:'取消'
    },
    request:{
        loadingText:'加载中...',
        Serverexception:'服务端异常'
    },
    newLang:{
        a1:"余额还差",
        a2:"当前任务数",
        "a3":"请先前往绑定提现地址",
        "a4":"余额不足",
        "a5":"是否前往充值",
    },
    "newLang2":{
        "a1":"已绑定地址，请联系客服修改",
        "a2":"需要充值",
        "a3":"请联系客服修改密码"
    }
};

