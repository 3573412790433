export default {
    logo: {
        name: 'Task Platform',
        msg: 'Task Platform',
        wz: 'Website: '
    },
    head: {
        Language: "English",
    },
    menu: {
        home: 'Home',
        order: 'Order',
        trading: 'Trading',
        earnings: 'Team',
        mine: 'User',
    },
    back: {
        appDownload: 'APP download',
        help: 'help',
        order: 'order',
        trading: 'Trading',
        income: 'Team',
        revenueDetails: 'Revenue details',
        Withdrawal: 'withdraw',
        Recharge: 'Recharge',
        Invitefriends: 'Invite friends',
        personalinformation: 'personal information',
        gywm: 'About Us',
        kf: 'service',
    },
    loading: {
        loadingText: 'Loading...',
        finishedText: "There's nothing more left",
        loadingText2: 'Matching...',
        loadingLogin: 'Logging in...',
        loadingSign: 'Registering...',
        confirmOrder: 'In transaction...'
    },
    Toast: {
        copy: 'Copy successful',
        ts: 'Please select language'
    },
    login: {
        title: 'Hello',
        msg: 'Sign in to your account',
        phone: 'phone',
        phoneCode: 'Area code',
        password: 'password',
        remember: 'Remember me',
        forget: 'Forgot password?',
        loginBtn: 'Login',
        noAccount: 'No account?',
        goRegister: 'Go register'
    },
    sign: {
        phone: 'phone',
        phoneCode: 'Area code',
        Nickname: 'Nickname',
        code: 'code',
        sendCode: 'Send code',
        password: 'password',
        confirmPassword: 'Confirm password',
        recommendationCode: 'Recommendation code',
        protocol: 'By registering you agree to our terms and conditions',
        registerBtn: 'Sign in',
        Existing: 'Existing account?',
        goLogin: 'Go log in',
        rule: {
            phone: ['Phone number cannot be empty', 'The phone number format is incorrect'],
            nickname: ['Nickname cannot be empty'],
            password: ['Password cannot be empty', 'Password with a minimum of 6 digits', 'Password inconsistency', 'Confirm password cannot be empty'],
            code: ['The verification code cannot be empty'],
            isAgree: ['Please check agree first']
        }
    },
    home: {
        cumulativeIncome: 'Cumulative income',
        notification: 'notification',
        moduleTitle1: 'My services',
        moduleTitle2: 'Partners'
    },
    // download: {
    //     Task Platform: 'Task Platform SHOP',
    //     AndroidDownload: 'Android download',
    //     iOSDownload: 'Apple Download',
    // },
    help: {
        'bz2': 'Frequently asked questions',
        'bz3': 'Transaction rules',
        'bz4': 'How to withdraw funds',
        'bz5': 'How to recharge',
        'bz6': '1.What is Task Platform?',
        'bz7': "Task Platform is a marketing and promotion company that helps Task Platform merchants around the world to obtain more order sales and increase browsing data for Task Platform online stores. We are committed to establishing a three-way profitable marketing and promotion model between Task Platform, merchants, and consumers. Combining the latest P2P blockchain technology, quickly connect consumers and merchants through USDT (TRC20, ERC20). Registered users can receive commission on orders, while merchants can increase their store's sales data. It is the latest profit model in the Internet blockchain model!",
        'bz8': '2.How does Task Platform work?',
        'bz9': 'According to daily feedback from Task Platform, when selling products that need improvement, users can easily click on the order and the system will automatically generate an order subscription. Users pay the order amount through blockchain USDT and receive daily commissions.',
        'bz10': '3.Why is there a difference in the price of the same currency between two transactions?',
        'bz11': "Price differences are caused by all factors that are unfavorable to the free circulation of currency, including the speed of currency transfer, network conditions, currency access restrictions, people's recognition of currency in different regions, and even the types and transactions provided by exchanges. Therefore, the same currency may generate price differences in different transactions.",
        'bz12': '4.Investment profit?',
        'bz13': 'The higher the price of the product you receive, the higher the profit of the order you receive. Meanwhile, Task Platform randomly allocates large commission orders.',
        'bz14': 'Every day, the system automatically generates and distributes 60 product orders to users. Users complete the payment for each order through USDT and receive a commission of 0.6%. Large commission orders are randomly allocated.',
        'bz15': 'USDT can be withdrawn after 60 orders. (If 60 orders are not completed, the system will automatically stop until the remaining order quantity on that day)',
        'bz16': 'Each account can only be bound to one USDT account. If an error occurs, please contact customer service',
        'bz17': 'After completing the daily order delivery, withdrawals can be made normally, and they will be credited to the account within 24 hours after the withdrawal is initiated, without any withdrawal restrictions',
        'bz18': 'Before recharging, please bind your withdrawal address (TRC-20 supported) USDT address. You can click on the recharge on the app or webpage, select the blockchain (TRC-20) you are using to recharge.',
        "bz19": "There are prohibited behaviors such as mutual recharge or bank transfers, and exchange transfers between related accounts. The behavior is suspected of maliciously grabbing, and it is found that the behavior will be punished by the account number"

    },
    order: {
        incomplete: 'Incomplete',
        completed: 'completed',
        orderNumber: 'OrderID',
        transactionTime: 'Transaction time',
        price: 'money',
        expectedReturns: 'Expected returns',
        submit: 'place order'

    },
    trading: {
        accountBalance: 'Account balance',
        introduce: 'Transaction Introduction',
        freeze: 'Completed',
        walletBalance: 'Total task',
        startMatching: 'Start matching',
        yield: "Today's earnings",
        orderNumber: 'OrderID',
        transactionTime: 'Transaction time',
        price: 'money',
        expectedReturns: 'Expected returns',
        startTrading: 'Start trading',
        cancel: 'Cancel',
        ts: 'Insufficient balance, still outstanding',
        msg: 'Task Platform uses feedback from Task Platform every day to showcase the products that need improvement sold by Task Platform. Users only need to click on the order, and the system will automatically generate an order signature. Users can receive a commission of more than 0.6% each time by paying the order amount through USDT blockchain, and the system will randomly allocate large reward orders.'
    },
    income: {
        totalRevenue: 'balance',
        residualIncome: 'commission',
        claim: 'Claim',
        moduleTitle: 'My team',
        headcount: 'headcount',
        totalRecharge: 'Total recharge',
        grade: 'user',
        numberOfPeople: 'Contribution (quantity)',
        income: 'state',
        viewDetails: 'View details',
        task_num: 'Incomplete',
        task_num2: 'Completed',
    },
    revenueDetails: {
        totalRevenue: 'Total revenue',
        headcount: 'headcount',
        totalRecharge: 'Total recharge',
        userLevel1: "user level 1",
        userLevel2: "user level 2",
        userLevel3: "user level 3",
        Nickname: 'Nickname',
        phone: 'phone',
        Recharge: 'Recharge',
        income: 'income',
        date: 'Registration time'
    },
    user: {
        Myaccount: 'My account',
        Withdrawal: 'Withdrawal',
        Recharge: 'Recharge',
        InviteFriend: 'Invite your friends',
        InviteFriendTxt: 'You will receive a corresponding commission for every profit earned by your friend after registration',
        InviteFriendBtn: 'Invitation',
        MyInformation: 'personal information',
        FundsDetails: 'Fund details',
        RechargeRecord: 'Recharge records',
        WithdrawalRecord: 'Withdrawal records',
        Language: 'language',
        Logout: 'Log out',
    },
    Withdrawal: {
        address: 'Withdrawal address',
        placeholder1: 'Withdrawal address',
        availableBalance: 'Available balance',
        placeholder2: 'Withdrawal amount',
        actualBalance: 'Actual receipt',
        placeholder3: 'Actual receipt',
        password: 'password',
        placeholder4: 'Please enter a 6-16 digit password',
        submit: 'Submit',
        amount: 'Cannot be greater than the available amount'
    },
    Recharge: {
        qrcode: 'Scan QR code to recharge',
        copy: 'Click to copy',
        cotakecarepy: 'Note that a relevant consideration is to use your screen or page for real estate',
    },
    Invitefriends: {
        inviteCode: 'Invitation code',
        inviteTxt: 'Invite friends and enjoy large profits. Come and invite them now',
        copy: 'Click to copy',
        Rebaterules: 'Click to copy',
        RebaterulesMsg: 'The following conditions must be met',
        scale: 'The commission ratio rule is as follows',
        Userlevel: ' User level ',
        Rules: 'rule',
        Commissionratio: 'commission rate',
        ps: 'The commission is settled in USDT and can be withdrawn to your wallet at any time'
    },
    personalinformation: {
        Nickname: 'Nickname',
        phone: 'phone',
        Loginpassword: 'Login password',
        address: 'Withdrawal address',
        modify: 'modify',
        Confirm: 'Confirm'
    },
    fundDetails: {
        amount: 'money',
        revenue: 'income',
        time: 'time',
        withdrawal: 'Withdrawal',
        recharge: 'Recharge',
        "type": {
            "1": "top up",
            "2": "withdraw",
            "3": "Commission collection",
            "4": "income",
            "5": "Withdraw",
            "6": "Pending",
            "7": "completed",
            "8": "rejected"
        }
    },
    language: {
        placeholder: 'global roaming',
        Confirm: 'Confirm',
        cancel: 'cancel'
    },
    request: {
        loadingText: 'Loading...',
        Serverexception: 'Server exception'
    },
    "newLang": { "a1": "The balance is poor", "a2": "Current task number", "a3": "Please go to the binding withdrawal address first", "a4": "Insufficient balance", "a5": "Whether to recharge" },
    "newLang2": {
        "a1": "For the binding address, please contact customer service to modify",
        "a2": "Need to recharge",
        "a3": "Please contact the customer service to modify the password"
    }
};
